import React from "react";
import Section from "../global/Section";
import homeBanner from "../../images/home/homebanner.png"
import features from "../../images/home/features.png"
import iosBtn from "../../images/downloadBtn/googleplay.png"


export default function Hero() {
  return (
  
    <Section
      secStyle={"sec-backgroundImg"}
    >
      <div className="row align-items-center">
        <div className="col-lg-7 col-md-7 col-sm-12">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-12 mt-5">
              <h2 className="font-w-extraBold text-center w d-block text-md-left text-lg-left font-italic mt-5">
                Play Fun Games<br/>
                Make New Friends
              </h2>
              <h3 className="w font-w-bold my-3 text-center text-md-left text-lg-left">
                100% Free, Forever. 
              </h3>
              <img src={features} width="70%" className="my-3 d-block m-auto ml-lg-0 ml-md-0"/>
            </div>
            <div className="col-sm-12 mt-4">
              <a href="https://play.google.com/store/apps/details?id=celerx.app.rewarding">
                <img src={iosBtn} className="d-block mx-auto mx-md-0 mx-lg-0 my-5" width="180px"/>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 offset-lg-1 offset-md-1 col-sm-12 ">
          <img src={homeBanner} className="d-block m-auto" width="100%"/>
        </div>
      </div>
    </Section>
    
  );
}
