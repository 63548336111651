import React, { Component } from "react"
import logo from "../../images/logo/mokai.svg"
import { Link } from "gatsby"
import "../style/navbar.scss"
// import PromotionHeader from "../global/PromotionNav"

if (typeof window !== "undefined") {
  // Make scroll behavior of internal links smooth
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

export default class NavBar2 extends Component {
  state = {
    navbarOpen: false,
    navToggle: "nav-bg",
    navLinkToggle: "links links-collapse",
    menuToggle: "",
  }

  navbarHandler = event => {
    this.state.navbarOpen
      ? this.setState({
          navbarOpen: false,
          navToggle: "nav-bg",
          navLinkToggle: "links links-collapse",
          menuToggle: "",
        })
      : this.setState({
          navbarOpen: true,
          navToggle: "nav-bg-active",
          navLinkToggle: "links links-collapse-active",
          menuToggle: "change",
        })
  }

  render() {
    return (
      <div className="nav-menu">
          <div className="nav-container">
            <div className="container">
              <div className="row justify-content-between" >
                <div className="align-self-center">
                  <Link to="/">
                    <img src={logo} height="44px" alt="logo" className="my-3"/>
                  </Link>
                </div>
                <ul className="link-container align-items-center justify-content-end">
                  <li className="d-none d-md-none d-lg-block">
                    <a href="https://play.google.com/store/apps/details?id=celerx.app.rewarding" className="btn" style={{backgroundColor:"#FAFF00"}}>
                      <p className="font-w-bold b">Play Now</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      </div>
    )
  }
}
